import { useMemo } from 'react';
import {
  getFormPersonalInitialValues,
  getFormPersonalValidationSchema,
} from './formPersonal.helper';
import { useTranslations } from '../../../translations';
import { useFormik } from 'formik';
import { useRegisterPopupContext } from '../../registerPopup';
import { updateUserProvider } from '../../../updateUser';
import {
  handleRegisterErrors,
  mapPersonalDataRequestParams,
} from '../registerForm.helper';
import { CreateUserError } from '../../../createUser';
import { useUser } from '../../../user';
import { getSimpleEvent, pushDataLayer } from '../../../../services';
import { RegisterEvents } from '../../register.analytics';
import { CommonEventTypes } from '../../../../analytics';

export const useFormPersonal = () => {
  const { reloadUser } = useUser();
  const {
    actionCallbacks,
    handleCloseRegister,
    formInitialValues,
    departments,
    positions,
    registerInitiator,
  } = useRegisterPopupContext();
  const translations = useTranslations();

  const initialValues = useMemo(
    () => getFormPersonalInitialValues(formInitialValues),
    []
  );

  const validationSchema = useMemo(
    () => getFormPersonalValidationSchema(translations),
    []
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    isValid,
    errors,
    touched,
    status,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, formikHelpers) => {
      await updateUserProvider(mapPersonalDataRequestParams(values))
        .then(() => {
          pushDataLayer(
            getSimpleEvent(
              RegisterEvents.registrationSuccess,
              CommonEventTypes.registration,
              registerInitiator || undefined
            )
          );
          handleCloseRegister();

          reloadUser();
          typeof actionCallbacks.onSuccess === 'function' &&
            actionCallbacks.onSuccess();
        })
        .catch((errors) => {
          pushDataLayer(
            getSimpleEvent(
              RegisterEvents.registrationError,
              CommonEventTypes.registration,
              registerInitiator || undefined
            )
          );
          handleRegisterErrors(errors as CreateUserError, formikHelpers);
        });
    },
  });

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    isValid,
    errors,
    touched,
    status,
    setFieldValue,
    departments,
    positions,
  };
};
