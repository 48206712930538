import React, { ReactElement, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommonEventTypes,
  CompanyUuidEnum,
  getCurrentPopupFromOpen,
  handleClosePopup,
  logger,
  Nullable,
  PopupsEnum,
  useTheme,
} from '@lerna-core';
import { CenteredPopupContainer } from '@features/common/popups';
import { useRouter } from 'next/router';
import { FormStatusContainer } from './formStatus';
import { sendUserOrderRequest } from '../userOrderForm.helper';
import {
  isConflictError,
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { FormWithoutPricesContainerProps } from './formWithoutPrices.model';
import { EXCEL_COURSE_UUID } from './formWithoutPrices.constants';
import { PageNameContext } from '@contexts';
import { FormContentContainer } from './formContent';

export const FormWithoutPricesContainer = ({
  course,
  user,
  translations,
  eventPosition,
  courseIndex,
}: FormWithoutPricesContainerProps): Nullable<ReactElement> => {
  const pageName = useContext(PageNameContext);
  const dispatch = useDispatch();
  const router = useRouter();
  const theme = useTheme();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);

  const isExcelCourse =
    course.uuid === EXCEL_COURSE_UUID &&
    user.career?.companyId === CompanyUuidEnum.mincifra;
  const showFormStatus = isSuccessDisplayed || isErrorDisplayed || isExistOrder;

  const handlePopupClose = (): void => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course,
      courseIndex
    );

    dispatch(handleClosePopup(PopupsEnum.userOrderFormPopup));
    clearData();
  };
  const clearData = (): void => {
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
    isExistOrder && setIsExistOrder(false);
  };
  const onSuccess = (): void => {
    setIsSuccessDisplayed(true);
  };

  const onError = (): void => {
    setIsErrorDisplayed(true);
  };
  const handleExistOrder = (isExist: boolean): void => {
    setIsExistOrder(isExist);
  };

  const handleSubmit = (): void => {
    setIsLoading(true);

    sendUserOrderRequest(user, course, router, false, false, pageName)
      .then((): void => {
        onSuccess();
      })
      .catch((error): void => {
        const conflictError = isConflictError(error);
        if (conflictError) {
          handleExistOrder(true);
        } else {
          onError();
          logger.error(
            `[ERROR]: error sending request. ${error.response?.data}`
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CenteredPopupContainer
      isOpen={!!currentPopup}
      handleClose={handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <>
        <FormContentContainer
          course={course}
          handleSubmit={handleSubmit}
          translations={translations}
          disabled={isLoading}
        />
        {showFormStatus && (
          <FormStatusContainer
            translations={translations}
            isSuccessDisplayed={isSuccessDisplayed}
            isErrorDisplayed={isErrorDisplayed}
            isExistOrder={isExistOrder}
            isExcelCourse={isExcelCourse}
          />
        )}
      </>
    </CenteredPopupContainer>
  );
};
