import { pageUrls } from '@constants';
import { PageNameContext } from '@contexts';
import { FbPixelContainer } from '@features/common/fbPixel';
import { PageScrollArrowContainer } from '@features/common/pageScrollArrow';
import { sendMindboxSphereEvent } from '@features/courses/coursesPage/coursesPage.helper';
import { ErrorPageContainer } from '@features/errorPage';
import { PageNotFoundContainer } from '@features/pageNotFound';
import {
  AlternateLinksContainer,
  CanonicalContainer,
  CatalogPageTypes,
  CookieNotificationContextProvider,
  FasttrackWidgetContainer,
  FiltersSlugEnum,
  getAlternateLinks,
  HeadSeoMetaTagsContainer,
  Nullable,
  PageStatusCodes,
  PageWrapperContainer,
  PromoCodeContextProvider,
  RegisterPopupContextProvider,
  TranslationsContext,
  useTheme,
  useViewPageDataLayer,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { COURSES_PAGE_ALTERNATE_LINKS } from '../courses.constants';
import { CoursesContainer } from '../courses.container';
import { CoursesPageWithErrorModel } from '../courses.model';
import { CoursesFooterContainer } from '../coursesFooter';
import { CoursesHeadContainer } from '../coursesHead';
import { CoursesPageContext } from './coursesPage.context';
import { ThemeProvider } from 'styled-components';
import { getCoursesCustomTheme } from '../courses.helper';

export const CoursesPageContainer = (
  props: CoursesPageWithErrorModel
): Nullable<ReactElement> => {
  const router = useRouter();
  const theme = useTheme();
  const alternateLinksArr = useMemo(
    () =>
      getAlternateLinks(
        COURSES_PAGE_ALTERNATE_LINKS,
        {},
        {},
        pageUrls.courses,
        router
      ),
    []
  );

  useViewPageDataLayer(CatalogPageTypes.courses);

  const Header = <CoursesHeadContainer />;
  const Content = <CoursesContainer />;
  const Footer = <CoursesFooterContainer />;

  if (props.statusCode === PageStatusCodes.serverError) {
    return <ErrorPageContainer />;
  } else if (props.statusCode === PageStatusCodes.errorNotFound) {
    return <PageNotFoundContainer coursesData={props} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    sendMindboxSphereEvent(router.query, props.filters);
  }, [router.query[FiltersSlugEnum.sphere], router.query.fastlink]);

  return (
    <ThemeProvider theme={getCoursesCustomTheme(props, theme)}>
      <PageNameContext.Provider value={CatalogPageTypes.courses}>
        <PromoCodeContextProvider props={props.promoCode}>
          <TranslationsContext.Provider value={props.translations}>
            <RegisterPopupContextProvider
              departments={props.departments}
              positions={props.positions}
              currencies={props.currencies}
              canClose
            >
              <CookieNotificationContextProvider>
                <CoursesPageContext.Provider value={props}>
                  <HeadSeoMetaTagsContainer seo={props.seo} />
                  <AlternateLinksContainer links={alternateLinksArr} />
                  <CanonicalContainer hrefWithWWW />
                  <FbPixelContainer />
                  <PageWrapperContainer
                    header={Header}
                    content={Content}
                    footer={Footer}
                  />
                  <FasttrackWidgetContainer />
                  <PageScrollArrowContainer />
                </CoursesPageContext.Provider>
              </CookieNotificationContextProvider>
            </RegisterPopupContextProvider>
          </TranslationsContext.Provider>
        </PromoCodeContextProvider>
      </PageNameContext.Provider>
    </ThemeProvider>
  );
};
