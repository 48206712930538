import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const RegisterForm = styled.div``;

export const RegisterFormTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
  white-space: pre-wrap;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
`;
