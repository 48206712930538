import { Store } from 'redux';
import { GetServerSidePropsContext } from 'next';
import {
  Department,
  departmentsByCompanyClientProvider,
  publicConfig,
  QueryParams,
  serverAuthorizationService,
  setAcceptLanguage,
  userPositionsByCompanyProvider,
  UserPositionWithOptions,
  userRedirectMiddleware,
} from '@lerna-core';
import { discountProgramCourseClientProvider } from '@features/common/discountProgram';
import { setSecureHeadersProps } from '@services';
import { CoursePageModel, CourseRequestParams } from './course.model';
import { courseClientProvider } from './courseClient.provider';

export const courseDataServerRequest = async (
  store: Store,
  context: GetServerSidePropsContext
): Promise<CoursePageModel> => {
  const params = context.query as CourseRequestParams;
  const user = store.getState().user.user;
  const secureHeaderProps = !!user ? setSecureHeadersProps() : {};
  const locale = context.locale || publicConfig.DEFAULT_LOCALE;
  const discountProgramQuery = params[QueryParams.discountProgram] as string;
  const promoCode = params[QueryParams.promoCode] as string;
  const requestHeaders = serverAuthorizationService.getAuthHeaders(
    context.req,
    context.res
  );

  const requests = [] as Promise<unknown>[];

  if (discountProgramQuery) {
    requests.push(
      discountProgramCourseClientProvider(
        {
          discount: discountProgramQuery,
          slug: params.slug,
          ...(promoCode && { promo_code: promoCode }),
        },
        {
          ...setAcceptLanguage(locale),
          ...secureHeaderProps,
          ...requestHeaders,
        }
      )
    );
  } else {
    requests.push(
      courseClientProvider(
        params,
        {
          ...setAcceptLanguage(locale),
          ...secureHeaderProps,
          ...requestHeaders,
        },
        user
      )
    );
  }

  const companyId = user?.career?.companyId;
  const isVerified = !!user?.career?.isVerified;

  if (companyId && !isVerified) {
    requests.push(
      departmentsByCompanyClientProvider(companyId),
      userPositionsByCompanyProvider(companyId)
    );
  }
  const requestResults = await Promise.all(requests);
  const courseData = requestResults[0] as CoursePageModel;
  const departments = requestResults[1] as Department[];
  const positions = requestResults[2] as UserPositionWithOptions[];

  courseData.referer = context.req.headers.referer || null;

  userRedirectMiddleware(context, courseData.user);

  return { ...courseData, departments, positions };
};
