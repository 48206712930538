import { Nullable } from '../../../models';
import { UserAggregateModel } from '../../user';
import { cookieService, dateService } from '../../../services';
import { REGISTER_COOKIE } from './registerPopup.constants';
import { RegisterCookieModel } from './registerPopup.model';

export const canUseRegisterPopup = (
  user: Nullable<UserAggregateModel>
): boolean =>
  !!(
    user?.career?.isPersonalDataFillOptional &&
    (!user?.career.isVerified || !user?.personal.isFulfilled)
  );

export const canUseRegisterWarning = (
  user: Nullable<UserAggregateModel>
): boolean =>
  canUseRegisterPopup(user) &&
  !(
    user?.career?.isBonusPointsHiddenOnListing ||
    user?.career?.isPricesHiddenOnListingCards ||
    user?.bonuses.points === 0
  );

export const canUseRegisterWelcome = (
  user: Nullable<UserAggregateModel>
): boolean =>
  canUseRegisterPopup(user) &&
  !(
    user?.career?.isBonusPointsHiddenOnListing ||
    user?.career?.isPricesHiddenOnListingCards ||
    user?.bonuses.points === 0
  );

export const getRegisterCookie = (): Nullable<RegisterCookieModel> => {
  const registerWelcomeCookie = cookieService.getCookie(REGISTER_COOKIE);

  if (registerWelcomeCookie) {
    return JSON.parse(registerWelcomeCookie) as RegisterCookieModel;
  }

  return null;
};

export const setRegisterCookie = (values: Partial<RegisterCookieModel>) => {
  const registerCookie = getRegisterCookie();

  cookieService.setCookie({
    name: REGISTER_COOKIE,
    val: JSON.stringify({ ...registerCookie, ...values }),
    days: dateService.yearInDays,
  });
};
