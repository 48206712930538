import { useCallback, useMemo } from 'react';
import {
  getFormB2BInitialValues,
  getFormB2BValidationSchema,
} from './formB2B.helper';
import { useTranslations } from '../../../translations';
import { useFormik } from 'formik';
import { SelectedValue } from '../../../form';
import { useRegisterPopupContext } from '../../registerPopup';
import { handleRegisterErrors } from '../registerForm.helper';
import { CreateUserError } from '../../../createUser';
import { updateUserProfileClientProvider, useUser } from '../../../user';
import { getSimpleEvent, pushDataLayer } from '../../../../services';
import { RegisterEvents } from '../../register.analytics';
import { CommonEventTypes } from '../../../../analytics';

export const useFormB2B = () => {
  const { reloadUser } = useUser();
  const {
    actionCallbacks,
    handleCloseRegister,
    formInitialValues,
    departments,
    positions,
    registerInitiator,
  } = useRegisterPopupContext();
  const translations = useTranslations();

  const initialValues = useMemo(
    () => getFormB2BInitialValues(formInitialValues, departments, positions),
    []
  );

  const validationSchema = useMemo(
    () => getFormB2BValidationSchema(translations),
    []
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    isValid,
    errors,
    touched,
    status,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, formikHelpers) => {
      updateUserProfileClientProvider(values)
        .then(() => {
          pushDataLayer(
            getSimpleEvent(
              RegisterEvents.registrationSuccess,
              CommonEventTypes.registration,
              registerInitiator || undefined
            )
          );
          handleCloseRegister();
          reloadUser();
          typeof actionCallbacks.onSuccess === 'function' &&
            actionCallbacks.onSuccess();
        })
        .catch((errors) => {
          pushDataLayer(
            getSimpleEvent(
              RegisterEvents.registrationError,
              CommonEventTypes.registration,
              registerInitiator || undefined
            )
          );
          handleRegisterErrors(errors as CreateUserError, formikHelpers);
        });
    },
  });

  const defaultDepartment = useMemo(
    () => departments.find(({ key }) => key === values.departmentUuid),
    [departments]
  );

  const defaultPosition = useMemo(
    () => positions.find(({ key }) => key === values.positionUuid),
    [positions]
  );

  const handleSelect = useCallback(({ name = '', value }: SelectedValue) => {
    setFieldValue(name, value.key);
  }, []);

  return {
    handleSubmit,
    handleChange,
    handleSelect,
    handleBlur,
    values,
    isSubmitting,
    isValid,
    errors,
    touched,
    status,
    setFieldValue,
    defaultDepartment,
    departments,
    defaultPosition,
    positions,
  };
};
