import { publicConfig } from '../services';
import { projectTypes } from '../constants';

export enum CommonEvents {
  pageLoaded = 'page_loaded',
  goToMainCoursesPageLogo = 'go_to_main_courses_page_logo',
  clickButtonAskQuestion = 'click_button_ask_question',
  clickButtonImportOfEmployees = 'click_button_import_of_employees',
  clickButtonShowMoreCourses = 'click_button_show_more_courses',
  formAskQuestionOpen = 'form_ask_question_open',
  formAskQuestionClose = 'form_ask_question_close',
  formAskQuestionSubmitSuccess = 'form_ask_question_submit_success',
  formAskQuestionSubmitError = 'form_ask_question_submit_error',
}

export enum CommonEventTypes {
  accountEvents = 'account_events',
  registration = 'event_registration',
  coursesEvents = 'courses_events',
  orderEvents = 'order_events',
  overallEvents = 'overall_events',
  accountOverallEvents = 'account_overall_events',
}

export enum CommonEventPositions {
  accountPageBlock = 'account_page_block',
  coursesPageList = 'courses_page_list',
  coursesPageBlock = 'courses_page_block',
  coursePageBlock = 'course_page_block',
  coursePageBlockMain = 'course_page_block_main',
  coursePagePrice = 'course_page_block_price',
  coursePageFixed = 'course_page_block_fixed',
  personalBlockHeader = 'personal_account_page_block_header',
  hrBlockHeader = 'hr_account_page_block_header',
  footer = 'footer',
  formOrderStatus = 'form_status_order',
}

export enum UsagePositions {
  header = 'header',
  help = 'help',
  navigation = 'navigation',
  tests = 'tests',
  testing = 'testing',
}

export const getProjectEventPosition = (
  usagePosition?: UsagePositions
): string | undefined => {
  const appType = publicConfig?.APP_TYPE;
  const position = usagePosition ? `_${usagePosition}` : '';

  if (appType === projectTypes.courses) {
    return;
  }

  return `${appType}_${CommonEventPositions.accountPageBlock}${position}`;
};

export const getProjectEventType = (): string => {
  const appType = publicConfig?.APP_TYPE;

  return `${appType}_${CommonEventTypes.accountOverallEvents}`;
};
